export const CREATE_SHORT_LINK = 'CREATE_SHORT_LINK';
export const UPDATE_SHORT_LINK = 'UPDATE_SHORT_LINK';
export const DELETE_SHORT_LINK = 'DELETE_SHORT_LINK';
export const ADD_CREATED_LINK = 'ADD_CREATED_LINK';
export const REMOVE_CREATED_LINK = 'REMOVE_CREATED_LINK';
export const GET_OWNED_LINKS = 'GET_OWNED_LINKS';
export const SET_OWNED_LINKS = 'SET_OWNED_LINKS';
export const REMOVE_OWNED_LINK = 'REMOVE_OWNED_LINK';

export const LOGOUT = 'LOGOUT';
export const PROCESS_LOGIN = 'PROCESS_LOGIN';
export const CLOSE_ACCOUNT = 'CLOSE_ACCOUNT';

export const ADD_SUCCESS_TOAST = 'ADD_SUCCESS_TOAST';
export const ADD_FAILURE_TOAST = 'ADD_FAILURE_TOAST';
